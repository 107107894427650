// import logo from "assets/images/brands/brand-main-sm.png";
import logo from "assets/images/brands/logo.png";
import light from "assets/images/brands/Enovyx Logo CMYK V1 Positive-111.png";
import dark from "assets/images/brands/Enovyx Logo CMYK V1 Negative-1.png";
import logosm from "assets/images/brands/Enovyx Logo CMYK V2 Negative-1-Photoroom.png-Photoroom.png";
import lightsm from "assets/images/brands/Enovyx Logo CMYK V2 Positive-1-Photoroom.png-Photoroom.png";
import footer from "assets/images/brands/Enovyx Logo CMYK Minimal Positive-1-Photoroom.png-Photoroom.png";
import footerLight from "assets/images/brands/Enovyx Logo CMYK Minimal Negative-1.png";

export const clientName = "Enovyx";
export const developedBy = "Enovyx";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_URL;
export const companyName =  "Enovyx";
export const sidebarLogo = logo;
export const smLogo = logosm;
export const smLightLogo = lightsm;
export const mainLogo = logo;
export const lightLogo = light;
export const darkLogo = dark;
export const footerLogo = footer;
export const footerLightLogo = footerLight;
