import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal, Button,
  ModalHeader,
  ModalBody,
  Alert,
} from "reactstrap";
import {
  AvForm, AvField, AvCheckboxGroup, AvCheckbox
} from "availity-reactstrap-validation";
import Loader from "components/Common/Loader";
import useModal from "hooks/useModal";
import AvFieldSelect from "components/Common/AvFieldSelect";
import validatePositiveInputs from "helpers/validatePositiveInputs";
import { addAccountType } from "store/actions";
import platforms from "common/platform";
import { getOffers } from "apis/tradingAccounts";
import AsyncAvFieldSelect from "components/Common/AsyncAvFieldSelect";

const PLATFORMS = platforms;
const TYPES = [
  // "LIVE", "DEMO","IB", "MAM", "INVESTOR"
  "LIVE", "DEMO"
];
const LEVERAGE = [1, 25, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000];
const CURRENCIES = ["USD", "EUR", "GBP", "EGP"];

function AddAccountType(props) {
  const dispatch = useDispatch();

  const [showModal, toggleModal] = useModal(false);
  const [leverageOptions, setLeverageOptions] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [type, setType] = useState();
  const [isOffersLoading, setIsOffersLoading] = useState(false);
  const [platform, setPlatform] = useState("");
  const [offers, setOffers] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);

  const create = useSelector((state) => state.Profile?.AccTypesPermissions?.create);
  const { submitting, error } = useSelector((state) => state.tradingAccountReducer);

  useEffect(() => {
    (!submitting && !error && showModal) && toggleModal();
  }, [submitting]);

  const isMamPam = ["MAM", "INVESTOR"].includes(type);

  const handleSubmit = (e, v) => {
    if (platform === "MATCH_TRADER") {
      if (v.visibility.length > 0) {
        // convert visibility array to boolean properties
        v.visibility = v.visibility.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {});
        v = {
          ...v,
          ...v.visibility,
        };
      }
      delete v.visibility;
      v.currencies = v.currencies.reduce((acc, curr) => {
        acc.push({
          currency: curr,
          groupPath: v[`${curr}GroupPath`].uuid,
          leverage: v[`${curr}GroupPath`].leverage,
        });
        delete v[`${curr}GroupPath`];
        return acc;
      }, []);
      return dispatch(addAccountType({
        ...v,
      }));
    }
    if (type !== "MAM") {
      if (v.visibility.length > 0) {
        // convert visibility array to boolean properties
        v.visibility = v.visibility.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {});
        v = {
          ...v,
          ...v.visibility,
        };
      }
      delete v.visibility;
      // add groupPath to currencies array
      v.currencies = v.currencies.reduce((acc, curr) => {
        acc.push({
          currency: curr,
          groupPath: v[`${curr}GroupPath`],
        });
        delete v[`${curr}GroupPath`];
        return acc;
      }, []);
      console.log(v);
      dispatch(addAccountType({
        ...v,
        platform: type === "INVESTOR" ? "MT5" : v.platform,
      }));
    } else {
      v.currencies = v.currencies.reduce((acc, curr) => {
        if (type === "MAM") {
          acc.push({
            isMaster: true,
            currency: curr,
            groupPath: v[`${curr}MasterGroupPath`],
          }, {
            isMaster: false,
            currency: curr,
            groupPath: v[`${curr}FeeGroupPath`],
          });
          delete v[`${curr}MasterGroupPath`];
          delete v[`${curr}FeeGroupPath`];
          return acc;
        } else {
          acc.push({
            isMaster: false,
            currency: curr,
            groupPath: v[`${curr}GroupPath`],
          });
          delete v[`${curr}GroupPath`];
          return acc;
        }
      }
      , []);
      dispatch(addAccountType({
        ...v,
        forCrm: true,
        forCp: false,
        platform: "MT5"
      }));
    }
  };

  const fetchOffers = () => {
    setIsOffersLoading(true);
    getOffers({
      isDemo: type === "DEMO",
    }).then((resp) => {
      setOffers(resp.result);
      setIsOffersLoading(false);
    }).finally(() => {
      setIsOffersLoading(false);
    });
  };

  useEffect(() => {
    platform === "MATCH_TRADER" && type && fetchOffers();
  }, [platform, type]);

  useEffect(() => {
    return () => {
      setOffers([]);
      setSelectedOffers([]);
    };
  }, []);


  return (
    <>
      <Link to="#" className={`btn btn-primary ${!create ? "d-none" : ""}`} onClick={toggleModal}><i className="bx bx-plus me-1"></i> Add New Type</Link>
      <Modal isOpen={showModal} toggle={toggleModal} centered={true}>
        <ModalHeader toggle={toggleModal} tag="h4">
          Add New Type
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='px-4'
            onValidSubmit={(e, v) => { handleSubmit(e, v) }}
          >
            <AvField
              name="title"
              label="Title"
              placeholder="Select Title"
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Title is required"
                }
              }}
            />
            <AvFieldSelect
              name="type"
              label="Type"
              placeholder="Select Type"
              options={TYPES.map((type) => ({
                value: type,
                label: type
              }))}
              value={type}
              onChange={(e) => setType(e)}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Type is required"
                }
              }}
            />
            {
              !isMamPam &&
              <AvFieldSelect
                name="platform"
                label="Platform"
                placeholder="Select Platform"
                options={PLATFORMS.map((type) => ({
                  value: type,
                  label: type
                }))}
                onChange={(e) => setPlatform(e)}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Platform is required"
                  }
                }}
              />
            }
            {
              platform !== "MATCH_TRADER" &&
              <>
                <AvFieldSelect
                  name="leverages"
                  label="Leverage Options"
                  placeholder="Select Leverage Options"
                  ismulti="true"
                  options={LEVERAGE.map((type) => ({
                    value: type,
                    label: type
                  }))}
                  onChange={(e) => {
                    setLeverageOptions(e);
                  }}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Leverage options is required"
                    }
                  }}
                />
                <AvFieldSelect
                  name="defaultLeverage"
                  label="Default Leverage"
                  placeholder="Select Default Leverage"
                  options={leverageOptions.map((type) => ({
                    value: type,
                    label: type
                  }))}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Default leverage is required"
                    }
                  }}
                />
                <AvField
                  name="sequence"
                  label="Sequence"
                  placeholder="Select Sequence"
                  type="number"
                  min="0"
                  onKeyPress={(e) => {
                    validatePositiveInputs(e);
                  }}
                  // validate={{
                  //   required: {
                  //     value: true,
                  //     errorMessage: "Sequence is required"
                  //   }
                  // }}
                />
              </>
            }
            
            <AvFieldSelect
              name="currencies"
              label="Currencies"
              placeholder="Select Currencies"
              ismulti="true"
              options={CURRENCIES.map((type) => ({
                value: type,
                label: type
              }))}
              onChange={(e) => {
                setCurrencies(e);
              }}
              validate={{
                required: {
                  value: true,
                  errorMessage: "Currencies is required"
                }
              }}
            />
            {
              isOffersLoading ? <Loader /> :
                <>
                  {
                    currencies.length > 0 &&
                    currencies.map((currency) => (
                      platform === "MATCH_TRADER" ? <>
                        <AsyncAvFieldSelect
                          key={currency}
                          name={`${currency}GroupPath`}
                          label={`${currency} Group`}
                          options={offers.map((offer) => ({
                            value: offer,
                            label: `${offer.name} | ${offer.groupName}`
                          }))}
                          errorMessage={(`${currency} is required`)}
                          defaultOptions={offers
                            .filter((offer) => offer.currency === currency
                              && (offer.demo === ( type === "DEMO")))
                            .map((offer) => ({
                              value: offer,
                              label: `${offer.name} | ${offer.groupName}`
                            }))}
                          onChange={(e) => {
                            if (e?.value) {
                              // find if the currency is already selected
                              const isSelected = selectedOffers.find((offer) => offer.currency === currency);
                              if (isSelected) {
                                setSelectedOffers((prev) => prev.filter((offer) => offer.currency !== currency));
                              }
                              setSelectedOffers((prev) => [...prev, e.value]);
                            } else {
                              setSelectedOffers((prev) => prev.filter((offer) => offer.currency !== currency));
                            }
                          }}
                          isRequired
                          placeholder={`${currency} Group`}
                          isSearchable={true}
                          backspaceRemovesValue={true}
                        />
      
                      </>
                        : (
                          type === "MAM" ? <>
                            <AvField
                              key={currency}
                              name={`${currency}MasterGroupPath`}
                              label={`${currency} Master Group Path`}
                              placeholder={`Select ${currency} Master group path`}
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: `${currency} master group path is required`
                                }
                              }}
                            />
                            <AvField
                              key={currency}
                              name={`${currency}FeeGroupPath`}
                              label={`${currency} Fee Group Path`}
                              placeholder={`Select ${currency} Fee group path`}
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: `${currency} Fee group path is required`
                                }
                              }}
                            />
                          </>
                            : <>
                              <AvField
                                key={currency}
                                name={`${currency}GroupPath`}
                                label={`${currency} Group Path`}
                                placeholder={`Select ${currency} group path`}
                                type="text"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: `${currency} group path is required`
                                  }
                                }}
                              />
                            </>
                        )
                    ))
                  }
                </>
            }
            {
              selectedOffers.length > 0 && <>{
                selectedOffers.map((offer) => (
                  <Alert key={offer.currency} color="success">
                    {offer.currency} group path: {(offer.groupName)} | Leverage: {(offer.leverage)}
                  </Alert>
                ))
              }</>
            }
            {type !== "MAM" && <>
              <AvCheckboxGroup inline name="visibility">
                <AvCheckbox label="For CRM" value="forCrm" />
                <AvCheckbox label="For CP" value="forCp" />
              </AvCheckboxGroup>
            </>}
            <div className='text-center mt-3 p-2'>
              {
                submitting
                  ? <Loader />
                  : <Button disabled={props.addLoading || selectedOffers.length === 0 || (currencies.length !== selectedOffers.length)} type="submit" color="primary">
                    Add
                  </Button>
              }
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
}

export default AddAccountType;