import {
  isMT5Enabled, isMT4Enabled, isMatchTraderEnabled
} from "../config";

const platforms = [];

if (isMT5Enabled) {
  platforms.push("MT5");
}

if (isMT4Enabled) {
  platforms.push("MT4");
}

if (isMatchTraderEnabled) {
  platforms.push("MATCH_TRADER");
}

export default platforms;